.PopoverContent {
    border-radius: 4px;
    padding: 20px;
    width: 330px;
    background-color: white;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
}

.PopoverArrow {
    fill: white;
}

.PopoverClose {
    font-family: inherit;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--red-11);
    position: absolute;
    top: 3px;
    right: 3px;
}
.PopoverClose:hover {
    background-color: var(--red-4);
}
.PopoverClose:focus {
    box-shadow: 0 0 0 2px var(--red-7);
}

.IconButton {
    font-family: inherit;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--red-11);
    background-color: white;
    box-shadow: 0 2px 10px var(--black-a7);
}
.IconButton:hover {
    background-color: var(--red-3);
}
.IconButton:focus {
    box-shadow: 0 0 0 2px black;
}

.IconButtonDisabled {
    font-family: inherit;
    border-radius: 100%;
    height: 50px;
    width: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--red-a5);
    background-color: white;
    box-shadow: 0 2px 10px var(--gray-a7);
}
.IconButtonDisabled:hover {
    background-color: var(--red-a1);
}
.IconButtonDisabled:focus {
    box-shadow: 0 0 0 2px white;
}


.Text {
    margin: 0;
    color: var(--black-a12);
    font-size: 15px;
    line-height: 19px;
    font-weight: 500;
}
