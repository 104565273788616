@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/green.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css';

/* reset */
button,
fieldset,
input {
    all: unset;
}

.PopoverContent {
    border-radius: 4px;
    padding: 20px;
    width: 250px;
    background-color: white;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    will-change: transform, opacity;
}

.TabsList {
    flex-shrink: 0;
    display: flex;
    border-bottom: 1px solid var(--mauve-6);
}

.TabsTrigger {
    font-family: inherit;
    background-color: white;
    padding: 0 20px;
    height: 45px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    line-height: 1;
    color: var(--mauve-11);
    user-select: none;
}
.TabsTrigger:first-child {
    border-top-left-radius: 6px;
}
.TabsTrigger:last-child {
    border-top-right-radius: 6px;
}
.TabsTrigger:hover {
    color: var(--red-11);
}
.TabsTrigger[data-state='active'] {
    color: var(--red-11);
    box-shadow: inset 0 -1px 0 0 currentColor, 0 1px 0 0 currentColor;
}
.TabsTrigger:focus {
    position: relative;
    box-shadow: 0 0 0 2px black;
}

.TabsContent {
    flex-grow: 1;
    padding: 5px;
    background-color: white;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    outline: none;
}
.TabsContent:focus {
    box-shadow: 0 0 0 2px black;
}

.Text {
    margin-top: 0;
    margin-bottom: 20px;
    color: var(--mauve-11);
    font-size: 15px;
    line-height: 1.5;
}

.Fieldset {
    margin-bottom: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.Label {
    font-size: 13px;
    line-height: 1;
    margin-bottom: 10px;
    color: var(--red-12);
    display: block;
}

.Input {
    flex: 1 0 auto;
    border-radius: 4px;
    padding: 0 10px;
    font-size: 15px;
    line-height: 1;
    color: var(--red-11);
    box-shadow: 0 0 0 1px var(--red-7);
    height: 35px;
}
.Input:focus {
    box-shadow: 0 0 0 2px var(--red-8);
}

.Button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    padding: 0 15px;
    font-size: 15px;
    line-height: 1;
    font-weight: 500;
    height: 35px;
}
.Button.green {
    background-color: var(--red-4);
    color: var(--red-11);
}
.Button.green:hover {
    background-color: var(--red-5);
}
.Button.green:focus {
    box-shadow: 0 0 0 2px var(--red-7);
}

.even {
    padding: 2px;
    background-color: black;
    color: white;
    border-radius: 10px;
}

.odd {
    padding: 2px;
    background-color: white;
    color: black;
    border-radius: 10px;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,.5);
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}
